/* eslint-disable camelcase */

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '../BasicFields/TextField'
import SelectionOption from './SelectionOption'
import { updateErrors } from '../SectionField'

// Use an counter to avoid duplicate id generation
let idCounter = 0
const idGenerator = () => `new-${new Date().getTime() + idCounter++}`

/*
 * TODO: Simplify events handle with useReducer
 * TODO: Simplify component state with useContext
 * TODO: Use react-uid to generate unique ids
 */
const Selection = ({
  question, getErrors, handleQuestionChange, handleQuestionDuplicate,
  handleQuestionRemove, dragHandleProps
}) => {
  const multi = question.context === 'selection_multi'
  const [state, setState] = useState({
    id: question.id,
    context: question.context,
    position: question.position,
    title: question.title,
    mandatory: question.mandatory,
    options: question.options,
    removed_option_ids: [],
    errors: question.errors || {}
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      errors: updateErrors(prevState.errors, getErrors)
    }))
  }, [getErrors])

  useEffect(() => {
    handleQuestionChange(state)
  }, [state, handleQuestionChange])

  const handleChange = useCallback(event => {
    const { name, value } = event.target
    setState(prevState => ({
      ...prevState,
      [name]: value
    }))
  }, [])

  const handleMandatoryChange = useCallback(event => {
    setState(prevState => ({
      ...prevState,
      mandatory: event.target.checked
    }))
  }, [])

  const handleClickNewOption = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      options: prevState.options.concat({ id: idGenerator(), content: '', default: false })
    }))
  }, [])

  const handleRemoveOption = useCallback(optionId => () => {
    setState(prevState => {
      let newlyRemovedOption = []
      const updatedOptions = prevState.options.filter(option => option.id !== optionId)

      if (!String(optionId).includes('new-'))
        newlyRemovedOption = prevState.removed_option_ids.concat(optionId)

      return {
        ...prevState,
        options: updatedOptions,
        removed_option_ids: newlyRemovedOption
      }
    })
  }, [])

  const handleOptionChange = useCallback(index => option => {
    setState(prevState => {
      const updatedOptions = [...prevState.options]
      updatedOptions[index] = option
      if (option.default && !multi) {
        updatedOptions.forEach((opt, optionIndex) => {
          if (optionIndex !== index)
            updatedOptions[optionIndex] = { ...opt, default: false }
        })
      }
      return {
        ...prevState,
        options: updatedOptions
      }
    })
  }, [])

  return (
    <div className="nested-fields ml-2 ml-md-4">
      <div className="d-flex">
        <div className="mr-auto">
          <i className="fas fa-arrows-alt c-pointer" {...dragHandleProps} />
          {
            multi ? ' Question avec choix multiples ' : ' Question avec choix unique '
          }
          <i
            className="fas fa-clone text-secondary c-pointer"
            onClick={handleQuestionDuplicate(state.id)}
          />
        </div>
        <i
          className="fas fa-trash danger-hover c-pointer"
          onClick={handleQuestionRemove(state.id)}
        />
      </div>
      <TextField
        name="title"
        value={state.title}
        placeholder="Titre de la question..."
        errors={state.errors.title}
        onChange={handleChange}
      />
      <div className="custom-control custom-switch mb-2">
        <input
          id={`mandatory-question-${state.id}`}
          type="checkbox"
          className="custom-control-input custom-switch-lg"
          checked={state.mandatory}
          onChange={handleMandatoryChange}
        />
        <label className="custom-control-label" htmlFor={`mandatory-question-${state.id}`}>
          Réponse obligatoire
        </label>
      </div>
      {state.options.map((option, index) => (
        <SelectionOption
          key={option.id}
          option={option}
          handleOptionChange={handleOptionChange(index)}
          handleRemoveOption={handleRemoveOption(option.id)}
          multi={multi}
        />
      ))}
      {
        state.errors && (state.errors.option_ids || state.errors.option_id) ? (
          <small className="text-danger">
            {(state.errors.option_ids || state.errors.option_id)[0]}
            <br />
          </small>
        ) : null
      }
      <button type="button" className="btn btn-outline-primary" onClick={handleClickNewOption}>
        Ajouter une option
      </button>
    </div>
  )
}

Selection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dragHandleProps: PropTypes.object.isRequired,
  getErrors: PropTypes.func.isRequired,
  handleQuestionChange: PropTypes.func.isRequired,
  handleQuestionDuplicate: PropTypes.func.isRequired,
  handleQuestionRemove: PropTypes.func.isRequired,
  question: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    context: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    mandatory: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.string.isRequired
    })).isRequired,
    errors: PropTypes.shape({
      title: PropTypes.arrayOf(PropTypes.string)
    })
  }).isRequired
}

export default Selection