import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '../BasicFields/TextField'

const GradeOption = ({ option, handleOptionChange, hidden = false, step }) => {
  const handleChange = useCallback(({ target: { name, value } }) => {
    handleOptionChange({ ...option, [name]: value })
  }, [handleOptionChange, option])

  const handleDefaultChange = useCallback(() => {
    handleOptionChange({ ...option, default: !option.default })
  }, [handleOptionChange, option])

  const circleClass = option.default ? 'option-circle text-success' : 'option-circle'
  const title = option.default ? 'Note par défaut' : 'Définir comme note par défaut'

  return (
    <>
      <div className="nested-fields ml-2 ml-md-4" hidden={hidden}>
        <div className={circleClass} title={title} onClick={handleDefaultChange}>
          {step}
        </div>
        <div className="option-field">
          <TextField name="content" value={option.content} onChange={handleChange} />
        </div>
      </div>
      <div className="clearfix" />
    </>
  )
}

GradeOption.propTypes = {
  handleOptionChange: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    content: PropTypes.string.isRequired,
    default: PropTypes.bool
  }).isRequired,
  step: PropTypes.number.isRequired
}

GradeOption.defaultProps = {
  hidden: false
}

export default GradeOption
