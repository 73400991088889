/* eslint-disable camelcase */

import React from 'react'

// Local import
import TextField from '../BasicFields/TextField'
import NumberField from '../BasicFields/NumberField'
import GradeOption from './GradeOption'
import { updateErrors } from '../SectionField'

// Use an counter to avoid duplicate id generation
let idCounter = 0
const idGenerator = () => `new-${new Date().getTime() + idCounter++}`

class Grade extends React.Component {
  constructor(props) {
    super(props)

    const options = props.question.options || []
    if (props.question.not_applicable &&
      options[options.length - 1] &&
      options[options.length - 1].content === 'Non applicable')
      options[options.length - 1].hidden = true

    this.state = {
      id: props.question.id,
      context: props.question.context,
      position: props.question.position,
      title: props.question.title,
      mandatory: props.question.mandatory,
      not_applicable: props.question.not_applicable || false,
      coefficient: props.question.coefficient || 100,
      options,
      removed_option_ids: [],
      errors: props.question.errors || {}
    }
  }

  shouldComponentUpdate(_nextProps, nextState) {
    this.state.errors = updateErrors(this.state.errors, this.props.getErrors)

    return (
      this.state.title !== nextState.title ||
      this.state.mandatory !== nextState.mandatory ||
      this.state.position !== nextState.position ||
      this.state.not_applicable !== nextState.not_applicable ||
      this.state.coefficient !== nextState.coefficient ||
      this.state.options !== nextState.options ||
      this.state.errors.isNew === true
    )
  }

  componentDidUpdate() {
    this.props.handleQuestionChange(this.state)
  }

  handleChange = event => this.setState({ [event.target.name]: event.target.value })

  handleMandatoryChange = event => this.setState({ mandatory: event.target.checked })

  handleNotApplicableChange = event => {
    this.setState(prevState => {
      let { options } = prevState
      let removedOptions = prevState.removed_option_ids

      if (event.target.checked)
        options.push({ id: idGenerator(), content: 'Non applicable', hidden: true })
      else {
        const removedOption = prevState.options.find(option => option.hidden === true)

        if (!String(removedOption.id).includes('new-'))
          removedOptions = removedOptions.concat(removedOption.id)

        options = options.filter(option => option.id !== removedOption.id)
      }

      return {
        not_applicable: event.target.checked,
        options,
        removed_option_ids: removedOptions
      }
    })
  }

  handleOptionChange = index => option => {
    this.setState(prevState => {
      const updatedOptions = [...prevState.options]
      updatedOptions[index] = option
      if (option.default) {
        updatedOptions.forEach((opt, i) => {
          if (i !== index)
            updatedOptions[i] = { ...opt, default: false }
        })
      }
      return {
        ...prevState,
        options: updatedOptions
      }
    })
  }

  optionList = () => {
    const optionElements = this.state.options

    for (let iterator = this.state.options.length; iterator < this.props.data.grade_size; iterator++)
      optionElements.push({ id: idGenerator(), content: '', default: false })

    optionElements.forEach((option, index) => {
      option.position = index
    })

    return (
      optionElements.map((option, index) => (
        <GradeOption
          key={option.id}
          hidden={option.hidden}
          step={index + (this.props.data.grade_zero_start ? 0 : 1)}
          option={option}
          handleOptionChange={this.handleOptionChange(index)}
        />
      ))
    )
  }

  render() {
    return (
      <div className="nested-fields ml-2 ml-md-4">
        <div className="d-flex">
          <div className="mr-auto">
            <i className="fas fa-arrows-alt c-pointer" {...this.props.dragHandleProps} />
            {' Question avec notation '}
            <i
              className="fas fa-clone text-secondary c-pointer"
              onClick={this.props.handleQuestionDuplicate(this.state.id)}
            />
          </div>
          <i
            className="fas fa-trash danger-hover c-pointer"
            onClick={this.props.handleQuestionRemove(this.state.id)}
          />
        </div>
        <TextField
          name="title"
          value={this.state.title}
          placeholder="Titre de la question..."
          errors={this.state.errors.title}
          onChange={this.handleChange}
        />
        <div className="custom-control custom-switch d-inline-block">
          <input
            id={`mandatory-question-${this.state.id}`}
            type="checkbox"
            className="custom-control-input custom-switch-lg"
            checked={this.state.mandatory}
            onChange={this.handleMandatoryChange}
          />
          <label className="custom-control-label" htmlFor={`mandatory-question-${this.state.id}`}>
            Réponse obligatoire
          </label>
        </div>
        <div className="custom-control custom-switch d-inline-block ml-2 mb-2">
          <input
            id={`not-applicable-${this.state.id}`}
            type="checkbox"
            className="custom-control-input custom-switch-lg"
            checked={this.state.not_applicable}
            onChange={this.handleNotApplicableChange}
          />
          <label className="custom-control-label" htmlFor={`not-applicable-${this.state.id}`}>
            Non applicable
          </label>
        </div>
        <NumberField
          name="coefficient"
          label="Coefficient"
          step={1}
          errors={this.state.errors.coefficient}
          value={this.state.coefficient}
          onChange={this.handleChange}
        />
        {this.optionList()}
      </div>
    )
  }
}

export default Grade
