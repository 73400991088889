import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '../BasicFields/TextField'

const SelectionOption = ({
  option,
  handleOptionChange,
  handleRemoveOption,
  multi = false
}) => {
  const handleChange = useCallback(({ target: { name, value } }) => {
    handleOptionChange({ ...option, [name]: value })
  }, [handleOptionChange, option])

  const handleDefaultChange = useCallback(() => {
    handleOptionChange({ ...option, default: !option.default })
  }, [handleOptionChange, option])

  const optionIcon = useMemo(() => {
    const icon = multi ? 'fa-square' : 'fa-circle'
    const weight = option.default ? 'far text-success' : 'far'
    const title = option.default ? 'Choix par défaut' : 'Définir comme choix par défaut'

    return (
      <i
        className={`${weight} ${icon} option-circle`}
        title={title}
        onClick={handleDefaultChange}
      />
    )
  }, [multi, option.default, handleDefaultChange])

  return (
    <>
      <div className="nested-fields ml-2 ml-md-4">
        {optionIcon}
        <div className="option-field">
          <TextField name="content" value={option.content} onChange={handleChange} />
        </div>
        <div className="option-trash">
          <i className="fas fa-trash danger-hover c-pointer" onClick={handleRemoveOption} />
        </div>
      </div>
      <div className="clearfix" />
    </>
  )
}

SelectionOption.propTypes = {
  handleOptionChange: PropTypes.func.isRequired,
  handleRemoveOption: PropTypes.func.isRequired,
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    content: PropTypes.string.isRequired,
    default: PropTypes.bool
  }).isRequired
}

export default SelectionOption